<template>
    <router-link :to=to class="btn" :class="[color, size]">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </router-link>
</template>


<script>
export default {
    name: 'ButtonGoCreate',
    props: {
        text: {
            type: String,
            default: '新規登録',
        },
        icon: {
            type: String,
            default: 'bi-plus',
        },
        type: {
            type: String,
            default: 'button',
        },
        to: {
            type: Object,
            default: () => {
                return {
                    name: null,
                    params: null,
                };
            }
        },
        color: {
            type: String,
            default: 'btn-outline-primary'
        },
        size: {
            type: String,
            default: ''
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
