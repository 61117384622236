<template>
    <page-title
        icon="bi-file-spreadsheet"
        title="エクスポート定義"
    >
        <button-go-create
            text="新規エクスポート定義"
            :to="{name: 'ExportLayoutAdd'}"
        ></button-go-create>
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="export_layouts.length">
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center">定義名</th>
                            <th class="text-center">タイプ</th>
                            <th class="text-center">最終更新日</th>
                            <th class="text-center">最終更新者</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="export_layout in export_layouts" :key="export_layout.export_layout_id">
                            <td class="align-middle">{{ export_layout.export_layout_name }}</td>
                            <td class="align-middle text-center">{{ export_layout.layout_type_display }}</td>
                            <td class="align-middle text-center">{{ export_layout.modified_datetime_display }}</td>
                            <td class="align-middle text-center">{{ export_layout.modified_user.user_name }}</td>
                            <td class="align-middle text-center">
                                <button-go-update
                                    :to="{name: 'ExportLayoutEdit', params: {id: export_layout.export_layout_id}}"
                                ></button-go-update>
                            </td>
                        </tr>
                    </tbody>
                </table-normal>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>
</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate.vue';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';
import ExportLayout from '@/models/entities/export-layout';

export default {
    name: 'ExportLayoutList',
    components: {
    InlineLoader,
    ButtonGoCreate,
    ButtonGoUpdate,
    TableNormal,
    AlertNoRecord,
    PageTitle,
},
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            //表示データ
            export_layouts: [],
        }
    },
    mounted() {
        this.fetchExportLayouts();
    },
    methods: {
        //エクスポート履歴マスタ取得
        fetchExportLayouts() {
            this.loading++;

            this.$http.get('/export-layout')
            .then(response => {
                for (let row of response.data) {
                    this.export_layouts.push(new ExportLayout(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
    }
}
</script>

<style scoped>

</style>
